body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  background-color: #0C5896;
  border-style: solid;
  border-width: thin;
  border-color: black;
  min-height: 2em;
}

.logo-navbar {
  height: 1em;
  width: auto;
  margin-left: 0em;
}

.login-menu {
  font-size: 0.9em;
  color: #3d64ff;
  font-weight: bold;
  margin-right: 1em;
}

.btn-login {
  background-color: #2181BC;
  color: white;
  font-size: x-small;
  float: right;
  display: block;
}

.btn-nuevo {
  font-size: x-small;
}

.navbar-menu {
  font-size: 0.9em;
  color: black;
  font-weight: bold;
  margin-right: 1em;
  padding-left: 2em;
  margin-top: 0.5em;
  font-family: "Roboto";
}

.navbar-menu:hover {
  color: #3d64ff;
}

a.nav-link {
  color: black !important;
  font-weight: 600;
}

a.nav-link:hover {
  color: #3d64ff !important;
}

.navbar-brand {
  margin-right: 0em !important;
}

.navbar-toggler {
  width: 2em !important;
}

.navbar-toggler-icon {
  width: 1em !important;
  margin-left: -0.1em !important;
}

.background {
  /*background-color: #6e83cd;*/
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
}

.background-fc {
  /*background-color: #6e83cd;*/
  background-color: #fafafa;
  border: 1px solid gray;
  border-radius: 5px;
}

.background-body {
  background-color: #e8fafe;
  margin-left: 1em;
  margin-right: 1em;
}

.linea {
  display: inline-block;
}

.linea-blanca {
  background-color: white;
}

.linea-negra {
  background-color: black;
}

.btn-background {
  /*background-color: #9ba8dd;*/
  background-color: #1961a7;
  color: white;
}

.text-black {
  color: black;
}

.titulos {
  color: #007AD9;
  font-weight: 800;
}

@media (min-width: 768px) {
  .navbar {
    background-color: #0C5896;
    border-style: solid;
    border-width: thin;
    border-color: black;
    min-height: 2.3em;
  }

  .logo-navbar {
    height: 2em;
    width: auto;
  }

  .btn-login-web {
    background-color: #2181BC;
    color: white;
    margin-right: 1em;
  }

  .btn-nuevo {
    font-size: large;
  }

  body .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.2em !important;
  }

  .mlpc-table {
    margin: 1em 1em 1em 1em;
    box-shadow: 5px 10px #888888;
  }

  .shadow-box {
    box-shadow: 5px 10px #888888;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 8px;
  }
  .MuiInput-root {
    margin-top: 16px;
  }

  #cbu.MuiInputBase-input.MuiInput-input {
    font-size: small !important;
  }
}
